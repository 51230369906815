<template>
  <div>
    <AlertModal :showModal="showModalCancelOrder">
      <template v-slot:header>
        <div class="d-flex flex-column">
          Atenção!
        </div>
      </template>
      <template v-slot:content>
        <div class="d-flex flex-column">
          <div class="mb-3">
            Ao executar a ação o Kplace tentará processar novamente o pedido com os dados da plataforma,
            se tudo ocorrer bem o pedido não irá mais aparecer nesta lista em alguns minutos.
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn outlined color="primary" class="mr-3" @click="closeModalReprocess">CANCELAR</v-btn>
        <v-btn color="primary" @click="reprocessOrderOcc" :disabled="isProcessing" :loading="isProcessing">SIM, REPROCESSAR</v-btn>
      </template>
    </AlertModal>
    <v-dialog
      persistent
      v-model="dialog">
      <template>
        <v-card class="modal-monitoring">
          <v-card-title style='padding-top: 20px;'>
            <h1 data-testid='page-title' class='description'>
              Detalhamento do alerta
            </h1>
            <v-spacer/>
            <v-btn color='#07BEE7' icon dark @click='close'>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style='padding-top: 20px;'>
            <v-row class="pl-3 pr-3">
              <v-col class="pr-15">
                <ReadOnlyTextField padding-top="20px" margin-right="5px" class="input-text" label-width="15%" label='Código Plataforma'
                                   v-model="form.number"/>
              </v-col>
            </v-row>
            <v-row class="pl-3 pr-3">
              <v-col class="pr-15">
                <ReadOnlyTextField padding-top="20px" margin-right="5px" class="input-text" label-width="15%" label='Data'
                                   v-model="form.submittedDate"/>
              </v-col>
            </v-row>
            <v-row class="pl-3 pr-3">
              <v-col class="pr-15">
                <ReadOnlyTextField padding-top="20px" margin-right="5px" class="input-text" label-width="15%" label='Status'
                                   v-model="form.status"/>
              </v-col>
            </v-row>
            <v-row>
              <v-data-table
                :headers="headers"
                :loading="loading"
                :items="form.historyResponses"
                class="elevation-1 custom-table"
                :server-items-length="form.historyResponses ? form.historyResponses.length : 0"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="d-flex justify-center">
                    <v-icon color="primary" @click="openDetailJson(item.request)">mdi-magnify-plus-outline</v-icon>
                    <v-icon color="primary" @click="sendEvent(item)">mdi-send-circle-outline</v-icon>
                  </div>
                </template>
                <template v-slot:no-data>
                  <div class="pt-10 pb-10">
                    Nenhum resultado encontrado
                  </div>
                </template>
              </v-data-table>
            </v-row>
          </v-card-text>
          <v-card-actions style='padding-top: 20px;'>
            <v-row class="alert-warning-actions">
              <v-btn color='primary' @click='alentReprocessOcc'>Reprocessar pedido</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>

      </template>
    </v-dialog>
    <v-dialog
      hide-overlay
      v-model="dialogJson"
      max-width="800"
      transition="dialog-bottom-transition"
      content-class="alert-card-dialog"
    >
      <template>
        <v-card class="modal-monitoring">
          <v-card-title style='padding-top: 20px;'>
            <h1 data-testid='page-title' class='description'>
              Json do evento
            </h1>
            <v-spacer/>
            <v-btn color='#07BEE7' icon dark @click='closeDetailJson'>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style='padding-top: 20px;'>
            <v-row>
              <json-viewer
                :expand-depth=5
                boxed
                style="width: 100%" :value="JSON.parse(dialogData)"></json-viewer>
            </v-row>
          </v-card-text>
          <v-card-actions style='padding-top: 20px;'>
            <v-row class="alert-warning-actions">
              <v-btn color='primary' @click="closeDetailJson()">
                Fechar
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>

      </template>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

import moment from 'moment/moment';
import JsonViewer from 'vue-json-viewer';
import ReadOnlyTextField from '@/components/template/form/input/ReadOnlyTextField';
import notification from '@/service/notification';
import { get, post } from '@/service/api';
// eslint-disable-next-line import/extensions
import AlertModal from '@/components/modal/AlertModal.vue';
// eslint-disable-next-line import/no-unresolved

export default Vue.extend({
  components: {
    ReadOnlyTextField,
    AlertModal,
    JsonViewer,
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      dialogJson: false,
      loading: false,
      dialogData: '{}',
      headers: [
        {
          text: 'Evento',
          align: 'start',
          sortable: false,
          value: 'event',
        },
        {
          text: 'Data e hora',
          align: 'start',
          sortable: false,
          value: 'createdAt',
        },
        {
          text: 'Resposta',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Ações',
          sortable: false,
          value: 'actions',
        },
      ],
      showModalCancelOrder: false,
      form: {},
      isProcessing: false,
    };
  },
  computed: {},
  methods: {
    close() {
      this.dialog = false;
      this.$emit('cancel');
    },
    closeDetailJson() {
      this.dialogJson = false;
    },
    openDetailJson(json) {
      this.dialogJson = true;
      this.dialogData = json;
    },
    async sendEvent(item) {
      console.log(item);
      const requestReponse = await post(`ui-integrator/hooks/mkp/histories/${item.id}/retry`, {
        headers: {},
      });
      if (requestReponse.status >= 200 && requestReponse.status <= 300) {
        this.closeModalReprocess();
        this.dialog = false;
        this.$emit('cancel');
        notification(`Tentativa de reenviar evento ${item.event} iniciada com sucesso.`);
      } else {
        console.log(requestReponse);
        notification('Erro ao tentar reenviar o evento');
      }
    },
    alentReprocessOcc() {
      this.showModalCancelOrder = true;
      this.isProcessing = false;
    },
    async reprocessOrderOcc() {
      this.isProcessing = true;
      const requestReponse = await post(`monitoring/orders/reprocess/${this.item.id}`, this.form, {
        headers: {},
      });
      if (requestReponse.status >= 200 && requestReponse.status <= 300) {
        this.closeModalReprocess();
        this.dialog = false;
        this.$emit('cancel');
        notification('Tentativa de reprocessar pedido efetuada com sucesso.');
        this.showModalCancelOrder = false;
      } else {
        this.isProcessing = false;
        notification('Erro ao tentar reprocessar o pedido.');
      }
    },
    closeModalReprocess() {
      this.showModalCancelOrder = false;
    },
    async getItemDetail() {
      try {
        this.form = {};
        this.loading = true;
        const requestReponse = await get(`monitoring/orders/${this.item.id}`);
        if (requestReponse.status >= 200 && requestReponse.status <= 300) {
          this.form = requestReponse.data;
          this.form.submittedDate = moment(requestReponse.data.submittedDate).format('DD/MM/YYYY');
          this.form.historyResponses.forEach((item) => {
            item.createdAt = moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss');
          });
        } else {
          notification('Erro ao buscar dados do monitoramento.');
        }
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    open(newVal) {
      if (this.dialog !== newVal) {
        this.dialog = newVal;
        this.getItemDetail();
      }
    },
  },
});
</script>

<style>
.alert-warning-actions {
  width: 100%;
  text-align: right !important;
  display: block;
  padding: 20px !important;
}

.modal-monitoring.theme--light.v-card {
  background-color: #f8f8f8;
  overflow: hidden;
}

.row-space {
  padding: 0 !important;
}

.dialog-title {
  font-weight: lighter;
}
</style>
