<template>
  <v-container class="crud-content" fill-height>
    <Detail
      :open='detailOpen'
      :item="orderSelected"
      v-on:cancel="cancel"/>
    <ListLayout :title="title" :info="info" :buttonActions="selected.length > 0 ? buttonActions: []">
      <template v-slot:filter>
        <filter-container>
          <template v-slot:content>
            <monitoring-form-filter @onSubmit="onSubmitFilterHandler" @onReset="onResetFilterHandler"/>
          </template>
        </filter-container>
      </template>
      <template v-slot:content>

        <v-data-table
          :headers="headers"
          :loading="loading"
          v-model="selected"
          show-select
          :items="content"
          class="elevation-1 custom-table"
          :options.sync="paginationOptions"
          :server-items-length="totalElements"
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-center">
              <v-icon color="primary" @click="openDetail(item)">mdi-magnify-plus-outline</v-icon>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </template>
    </ListLayout>
  </v-container>
</template>

<script>

import notification from '@/service/notification';
import dataCrudMixin from '../../components/template/crud/mixins/dataCrudMixin';
import Detail from '@/views/monitoring/detail/Detail';
// eslint-disable-next-line import/extensions
import MonitoringFormFilter from '@/views/monitoring/filter/MonitoringFormFilter.vue';
import ListLayout from '@/components/template/list/ListLayout';
import FilterContainer from '@/components/filter-layout/FilterContainer';
import getListMonitoring from '@/service/monitoring/monitoring-service';
import { post } from '@/service/api';

require('@/assets/css/monitoring/list.scss');

export default {
  components: {
    Detail,
    MonitoringFormFilter,
    ListLayout,
    FilterContainer,
  },
  mixins: [dataCrudMixin],
  watch: {
    paginationOptions: {
      handler() {
        this.init();
      },
    },
  },
  data() {
    return {
      loading: false,
      sortable: {
        submittedDate: 'DESC',
      },
      formDataFilter: {},
      paginationOptions: {},
      title: 'Monitoramento',
      info: '',
      selected: [],
      buttonActions: [{ name: 'Reprocessar pedidos', action: () => this.resendBatch() }],

      headers: [
        {
          text: 'Numero',
          align: 'start',
          sortable: false,
          value: 'number',
        },
        {
          text: 'Código Plataforma',
          align: 'start',
          sortable: false,
          value: 'marketPlaceId',
        },
        {
          text: 'Mensagem',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Plataforma',
          align: 'start',
          sortable: false,
          value: 'integrationPlatform',
        },
        {
          text: 'Data e hora',
          align: 'start',
          sortable: false,
          value: 'submittedDate',
        }, { text: 'Ações', sortable: false, value: 'actions' },
      ],
      footer: {
        'items-per-page-text': 'Itens por página',
      },
      orders: [],
      columnFunctions: [],
      showExpand: false,
      singleExpand: true,
      expandedHeaders: [],
      itemKey: 'key',
      detailOpen: false,
      orderSelected: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  methods: {
    async resendBatch() {
      const ids = this.selected.map((value) => value.id);

      const requestReponse = await post('monitoring/orders/reprocess', ids, {
        headers: {},
      });
      if (requestReponse.status >= 200 && requestReponse.status <= 300) {
        this.dialog = false;
        notification('Tentativa de reprocessar pedidos em lote iniciada com sucesso.');
        this.showModalCancelOrder = false;
      } else {
        notification('Erro ao tentar reprocessar os pedidos.');
      }
    },
    onSubmitFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getOrderMonitoringListHandler(this.formDataFilter);
    },
    onResetFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getOrderMonitoringListHandler(this.formDataFilter);
      this.paginationOptions.page = 1;
    },
    init() {
      this.getOrderMonitoringListHandler(this.formDataFilter);
    },
    async getOrderMonitoringListHandler(formDataFilter) {
      try {
        this.loading = true;
        const requestReponse = await getListMonitoring(formDataFilter, this.paginationOptions);

        if (requestReponse.status >= 200 && requestReponse.status <= 300) {
          this.content = requestReponse.data.register;
          this.totalElements = requestReponse.data.totalRegister;
        } else {
          notification(`${requestReponse.response.data.message}`, 'error');
        }
      } catch (e) {
        notification(`Erro ao realizar busca de pedidos : ${e}`, 'error');
      } finally {
        this.loading = false;
      }
    },
    openDetail(item) {
      this.orderSelected = item;
      this.detailOpen = true;
    },
    cancel() {
      this.detailOpen = false;
    },
  },
  async mounted() {
    if (this.paginationOptions.sortBy.length === 0) {
      this.paginationOptions.sortBy.push('submittedAt');
      this.paginationOptions.sortDesc.push(false);
    }
    await this.init();
  },

};
</script>
